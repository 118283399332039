import { CURRENT_USER } from "../constants";

const initialState = {
    currentUser: {},
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser
            };
        default:
            return state;
    }
}

export { accountReducer };
