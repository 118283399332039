import React, { useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import { useSelector, useDispatch } from "react-redux";
// import PouchDB from "pouchdb-browser";
import Nav from "../../components/navbar/Nav";
import axiosInstance from "../../axiosInstance";
import styles from "./styles/CategoryPage.module.css";
import VideoPopUp from "../../components/VideoPopUp/VideoPopUp";
import { setVideoPopUpAction } from "../../redux/actions/appAction";

const schema = Joi.object({
    hashInfo: Joi.string().required(),
}).required();

function CustomPage(props) {

    const dispatch = useDispatch();

    // const db = new PouchDB('weblover');

    const [jsonData, setJSONData] = useState({});
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: joiResolver(schema)
    });

    const { isShowVideoPopUp } = useSelector((state) => state.manageApp);

    const onSubmit = async ({ hashInfo }) => {
        setJSONData({});
        try {
            const { data } = await axiosInstance.post('/api/v1/web-play', { magnet: decodeURIComponent(hashInfo) });
            setJSONData(data);
            if (data.progress > 3) {
                dispatch(setVideoPopUpAction(true));
            }
        } catch (error) {
            setJSONData(error);
        }
    };


    return (
        <div className={styles.CategoryPage}>
            <Nav />
            <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl lg:px-8 mt-20">
                <div className="relative overflow-hidden rounded-2xl px-2 py-5 shadow-xl">
                    <div className="relative">
                        <form className="sm:mx-auto sm:flex" onSubmit={handleSubmit(onSubmit)}>
                            <div className="min-w-0 flex-1">
                                <label htmlFor="hashInfo" className="sr-only">
                                    Search
                                </label>
                                <input
                                    {...register("hashInfo")}
                                    id="hashInfo"
                                    type="text"
                                    className="block w-full rounded-md border border-transparent px-2 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                    placeholder="Enter the Hash"
                                />
                                <p>{errors.hashInfo?.message}</p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-3">
                                <button
                                    type="submit"
                                    className="block w-full rounded-md border border-transparent bg-pink-700 px-5 py-3 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                                >
                                    Show
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="relative overflow-hidden rounded-2xl px-1 shadow-xl">
                    <JSONTree data={jsonData} style={{ padding: 10 }} />
                </div>
                {jsonData && isShowVideoPopUp && <VideoPopUp videoHash={jsonData.infoHash} poster={null} />}
            </div>
        </div>
    )
}

export default CustomPage;
