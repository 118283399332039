import React, { useEffect, useState } from 'react'
import Nav from "../../components/navbar/Nav";
import axiosInstance, { requests } from "../../axiosInstance";
import styles from "./styles/CategoryPage.module.css";
import MovieCard from "../../components/moviecard/MovieCard";

function CategoryPage(props) {
    const [movies, setMovies] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        async function fetchMovies() {
            try {
                const res = await axiosInstance.get(`${requests[props.type]}`);
                const data = res.data.videos || [];
                if (data.length > 0) {
                    setMovies([...movies, ...data]);
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchMovies();
    }, [page]);

    function handleScroll(e) {
        let { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

        let scrolledToBottom = Math.floor(scrollHeight - scrollTop) === clientHeight || Math.ceil(scrollHeight - scrollTop) === clientHeight;

        console.log(scrolledToBottom);
        if (scrolledToBottom) {
            // setPage(page + 1);
        }
    }

    return (
        <div className={styles.CategoryPage}>
            <Nav />
            <h1 className={styles.category}>{props.genre}</h1>
            <div className={styles.movies__container} onScroll={handleScroll}>
                {movies && movies.map((movie, idx) => {
                    return <MovieCard key={idx} movie={movie} />
                })}
            </div>
        </div>
    )
}

export default CategoryPage
