import React from "react";
import { useParams } from "react-router-dom";
import Details from "../components/details/Details";
import Nav from "../components/navbar/Nav";

const DetailsPage = () => {
   const { videoHash } = useParams();
   return (
      <>
         <Nav />
         <Details videoHash={videoHash} />
      </>
   );
};

export default DetailsPage;
