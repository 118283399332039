import React from 'react';
import { useNavigate } from "react-router-dom";
import styles from "./styles/Banner.module.css";
import Logo from "../../assets/icons/Logo";

function Banner(props) {
    const navigate = useNavigate();

    function redirectToMovies() {
        navigate("/signIn");
    }

    return (
        <div className={styles.Banner}>
            <div className={styles.Banner__top}>
                <Logo />
                <button className={styles.Banner__btn} onClick={redirectToMovies}>Sign In</button>
            </div>

            <div className={styles.Banner__main}>
                <h1 className={styles.Banner__heading}>Web Streaming</h1>
            </div>
        </div>
    )
}

export default Banner
