import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "./Details.scss";
import VideoPopUp from "../VideoPopUp/VideoPopUp";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";

import { setVideoPopUpAction } from "../../redux/actions/appAction";

const Details = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [videoHash, setVideoHash] = useState("");
   const { isShowVideoPopUp, movieDetails } = useSelector((state) => state.manageApp);

   // #to close videoPopUp instead of going back to home page, when browser backBtn is clicked
   window.onpopstate = function () {
      isShowVideoPopUp && navigate(1);
   };

   const images = movieDetails.images.map(image => `url(${image})`);
   return (
      <div
         className="parentDivMovieDetails"
         style={{
            backgroundImage: images.join(', '),
            // backgroundImage: `url(${movieDetails?.poster})`,
            // backgroundImage: `url(https://image.tmdb.org/t/p/original/2JoIysXRYYeveJghG1Br53LRoKF.jpg)`,
            backgroundRepeat: 'no-repeat',
            // height: 200,
         }}
      >
         <div className="shadeDiv">
            <div className="flex-div"></div>
            <div className="movieContent">
               <div className="sidePoster">
                  <img className="sideImg" src={movieDetails?.poster} alt="Movie Poster" />
               </div>
               <div className="movieDetails">
                  <h1 className="title">{movieDetails?.name}</h1>
                  <p>
                     {(movieDetails?.createdAt) && `Updated Date : ${moment(movieDetails?.createdAt).format('MMMM Do YYYY, hh:mm A')}`}
                  </p>
                  <p>{movieDetails?.videoType && `Type : ${movieDetails.videoType}`} </p>
                  <br />
                  <div className="overview">
                     <ul>
                        {movieDetails?.videoQualities.map((quality, index) => {
                           return (<div key={index}>

                              <li>{quality.name} {quality.hash}</li>
                              <button className="teaserWatchBtn" onClick={async () => {
                                 try {
                                    const { data } = await axiosInstance.post(`/api/v1/web-play`, {
                                       magnet: quality.hash
                                    });
                                    if (data.infoHash) {
                                       setVideoHash(data.infoHash);
                                       dispatch(setVideoPopUpAction(true));
                                    }
                                 } catch (error) {
                                 }
                              }}>
                              <FaPlayCircle className="youtubeIcon" size={30} />
                                 Watch
                              </button>
                              <br />
                           </div>)
                        })}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         {isShowVideoPopUp && <VideoPopUp videoHash={videoHash} poster={movieDetails?.poster} />}
      </div>
   );
};

export default Details;
