import React, { useState } from "react";
import { Player, BigPlayButton, LoadingSpinner, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, ClosedCaptionButton } from 'video-react';
import { FaWindowClose } from "react-icons/fa";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import "./VideoPopUp.scss";
import { setVideoPopUpAction } from "../../redux/actions/appAction";

const VideoPopUp = ({ videoHash, poster }) => {

   const dispatch = useDispatch();

   const [isInitialVideoReady, setIsInitialVideoReady] = useState(false);

   // useEffect(() => {
   // axiosInstance.get(`/api/v1/web-play/stream/${videoHash}`)
   //    .then(({ data }) => {
   //       const blob = data.blob;
   //       setVideoURL(URL.createObjectURL(blob))
   //    });

   // axiosInstance.get(`/api/v1/web-play/stream/${videoHash}`, {
   //    responseType: 'blob'
   // })
   //    .then(r => r.blob())
   //    .then(blobFile => {
   //       console.log(blobFile);
   // const url = new File([blobFile], "fileName", { type: blobFile.type });
   // console.log(url);
   // });

   // const config = { responseType: 'blob' };
   // axiosInstance.get(`/api/v1/web-play/stream/${videoHash}`, config)
   // .then(response => {
   // console.log(response);
   // const url = new File([response.data], "fileName");
   // setVideoURL(url);
   // });

   // }, [videoURL]);

   return (
      <div className={`videoBgShade_movieDetails`}>
         {videoHash && (
            <div className="videoFrame">
               <BeatLoader
                  color="rgba(255, 255, 255, 0.8)"
                  loading={!isInitialVideoReady}
                  speedMultiplier={1.2}
                  size={10}
                  aria-label="Loading Spinner"
                  cssOverride={{
                     position: "absolute",
                     left: "50%",
                     top: "50%",
                     transform: "translate(-50%, -50%)",
                  }}
               />
               <Player
                  src={`https://api.play.weblover.cloud/api/v1/web-play/stream/${videoHash}`}
                  width="100%"
                  height="100%"
                  poster={poster}
                  playsInline={true}
                  controls={true}
                  autoPlay={false}
                  onReady={() => setIsInitialVideoReady(true)}
               >
                  <LoadingSpinner />
                  <ControlBar autoHide={false} className="my-video-player">
                     <ReplayControl seconds={5} order={2.1} />
                     <ForwardControl seconds={30} order={3.1} />
                     <VolumeMenuButton />
                     <ClosedCaptionButton order={7} />
                  </ControlBar>
                  <BigPlayButton position="center" />
               </Player>
            </div>
         )}
         {/* videoPopUp_control_btns */}
         <div className="videoControlBtns">
            <FaWindowClose
               className="videoCloseBtn"
               onClick={() => {
                  dispatch(setVideoPopUpAction(false));
               }}
            />
         </div>
      </div>
   );
};

export default VideoPopUp;
