import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance, { requests } from "../../axiosInstance";
import styles from "./styles/Row.module.css";
import { useNavigate } from "react-router-dom";

const linksObject = {
    "fetchTamilTCRip": "/category/tamil_hd",
    "fetchTamilHDRip": "/category/tamil_tc",
    "fetchTamilDubbed": "/category/tamil_dubbed",
}

function Row(props) {
    const navigate = useNavigate();
    let [movies, setMovies] = useState([]);

    useEffect(() => {
        async function fetchMovies() {
            const res = await axiosInstance.get(requests[props.type]);
            const data = res.data.videos || [];
            if (data.length > 0) {
                setMovies([...movies, ...data]);
            }
        }
        fetchMovies();
    }, []);


    const handleClick = (movie) => {
        navigate("/video/f9921a6572e9a14da2532330644c8f73b13ccd76");
    }

    return (
        <div className={styles.Row}>
            <Link to={linksObject[props.type]}> <h1>{props.genre}</h1> </Link>
            <div className={styles.Row_images}>
                {movies.map((movie, idx) => {
                    return <img src={`${movie.poster}`}
                        alt={movie.name} key={idx} className={styles.Row_image} onClick={() => handleClick(movie)}
                        style={props.isLarge && { maxHeight: "350px" }} />
                })}
            </div>
        </div>
    )
}

export default Row;
