import { MANAGE_APP } from "../constants";

const appAction = rawData => {
  return {
    type: MANAGE_APP.MANAGE_APP,
    payload: rawData
  }
}

const setMovieDetailsAction = movieDetails => {
  return {
    type: MANAGE_APP.MOVIE_DETAILS,
    payload: movieDetails
  }
}


const setVideoPopUpAction = isShowVideoPopUp => {
  return {
    type: MANAGE_APP.VIDEO_POPUP,
    payload: isShowVideoPopUp
  }
}

export {
  appAction,
  setVideoPopUpAction,
  setMovieDetailsAction,
};
