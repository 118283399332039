import React from 'react';
import { useNavigate } from "react-router-dom";
import SignIn from "../../components/signin/SignIn";
import styles from "./styles/SignInPage.module.css";
import Logo from "../../assets/icons/Logo";

function SignInPage(props) {

    const navigate = useNavigate();

    function redirectToHome() {
        navigate("/");
    }

    return (
        <div className={styles.Banner}>
            <div className={styles.Banner__top}>
                <Logo />
                <button className={styles.Banner__btn} onClick={redirectToHome}>Home</button>
            </div>

            <div className={styles.Banner__main}>
                <SignIn {...props} />
            </div>
        </div>
    )
}

export default SignInPage;