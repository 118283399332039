import axios from "axios";

axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
    baseURL: "https://api.play.weblover.cloud",
    // baseURL: "http://localhost:5000",
    withCredentials: true,
});

const requests = {
    fetchTamilHDRip: `/api/v1/videos?catalog=tamil_hdrip`,
    fetchTamilTCRip: `/api/v1/videos?catalog=tamil_tcrip`,
    fetchTamilDubbed: `/api/v1/videos?catalog=tamil_dubbed`,

    // fetchTopRated: `/data/catalog/movie/tamil_hdrip.json`,
}

export { requests, axiosInstance as default };