import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";
import axiosInstance from "../../axiosInstance";
import styles from "./styles/SignIn.module.css";

const schema = Joi.object({
  email: Joi.string().required(),
  password: Joi.string().required(),
}).required();

function Login(props) {
  const navigate = useNavigate();

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: joiResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const res = await axiosInstance.post('/api/v1/user-login', data);
      navigate("/movies");
    } catch (error) {

    }
  };

  return (
    <div className={styles.signIn}>
      <h1>Sign In</h1>
      <form className={styles.signIn__form} onSubmit={handleSubmit(onSubmit)}>
        <input type="text" placeholder="Email" {...register("email")} />
        <p>{errors.email?.message}</p>
        <input type="password" placeholder="Password" {...register("password")} />
        <p>{errors.password?.message}</p>
        <input className={styles.signIn_btn} type="submit" value={"Sign In"} />
      </form>
    </div>
  );
}

export default Login;
