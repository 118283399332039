import React from 'react'
import Banner from "../../components/banner/Banner";

function HomePage(props) {
    return (
        <Banner />
    )
}

export default HomePage
