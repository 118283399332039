import React from "react";
import Row from "../../components/row/Row";
import Header from "../../components/header/Header";
import Nav from "../../components/navbar/Nav";

function MoviesPage() {
  return (
    <>
      <Nav />
      <Header />
      <Row type="fetchTamilHDRip" genre="Tamil HDRip" isLarge />
      {/* <Row type="fetchTamilTCRip" genre="Tamil TCRip" /> */}
    </>
  )
}

export default MoviesPage;