import React from "react";
import { Routes, Route } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import 'video-react/dist/video-react.css';
import { rootReduxStore, persistor } from "./redux/store";
import HomePage from "./pages/home/HomePage";
import MoviesPage from "./pages/movies/MoviesPage";
import CategoryPage from "./pages/category/CategoryPage";
import SignInPage from "./pages/signin/SignInPage";
import DetailsPage from "./pages/DetailsPage";

import CustomPage from "./pages/custom/CustomPage";

function App() {
  return (
    <StoreProvider store={rootReduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path="/" index element={<HomePage />} />
          <Route path="/signIn" element={<SignInPage />} />
          <Route path="/movies" element={<MoviesPage />} />

          <Route path="/category/tamil_hd" element={<CategoryPage type="fetchTamilHDRip" genre="Tamil HDRip" />} />
          <Route path="/category/tamil_tc" element={<CategoryPage type="fetchTamilTCRip" genre="Tamil TCRip" />} />
          <Route path="/category/tamil_dubbed" element={<CategoryPage type="fetchTamilDubbed" genre="Tamil Dubbed" />} />

          <Route path="/hash-play" element={<CustomPage />} />

          <Route path="/video/:videoHash" element={<DetailsPage />} />
        </Routes>
      </PersistGate>
    </StoreProvider>
  )
}


export default App;
