import React from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./styles/MovieCard.module.css";
import { setMovieDetailsAction } from "../../redux/actions/appAction";
import axiosInstance from "../../axiosInstance";

function MovieCard({ movie }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const playVideo = async (id) => {
        try {
            const { data } = await axiosInstance.get(`/api/v1/videos/${id}`);
            dispatch(setMovieDetailsAction(data));
            navigate(`/video/${id}`);
        } catch (error) {

        }
    }
    return (
        <>
            <div className={styles.movie}>
                <img src={`${movie.poster}`} alt={movie.name} onClick={() => playVideo(movie._id)} />
                <p>{movie.name}</p>
                <div className={styles.movie__content}>
                    {(movie?.createdAt) && `${moment(movie?.createdAt).format('MMMM Do YYYY, hh:mm A')}`}
                    <button onClick={() => playVideo(movie._id)} className={styles.playBtn}>Play</button>
                </div>
            </div>
        </>
    )
}

export default MovieCard;
