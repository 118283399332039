import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IoMdPlay, IoMdAdd } from 'react-icons/io';
import axiosInstance, { requests } from "../../axiosInstance";
import styles from "./styles/Header.module.css";

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "....";
  } else {
    return str;
  }
}

function Header() {
  let [movie, setMovie] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchMovie() {
      let res = await axiosInstance.get(`${requests.fetchTamilHDRip}`);
      let movies = res.data.videos || [];
      if (movies.length > 0) {
        let movie = movies[Math.floor(Math.random() * movies.length)];
        setMovie(movie);
      }
    }
    fetchMovie();
  }, []);

  const playVideo = (movie) => {
    console.log(movie)
    navigate("/video/f9921a6572e9a14da2532330644c8f73b13ccd76");
  }

  return (
    <>
      <header className={styles.Header} style={{ backgroundImage: `url(${movie.poster})` }}>
        <div className={styles.Header_contents}>
          <h1>{movie.name}</h1>
          <div className={styles.Header_btns}>
            <button onClick={() => playVideo(movie)}>
              <IoMdPlay className={styles.playIcon} />Play
            </button>
            <button><IoMdAdd className={styles.addIcon} /> My List</button>
          </div>
          <p>{movie.overview && truncateString(movie.overview, 170)}</p>
        </div>
      </header>
    </>
  )
}

export default Header
