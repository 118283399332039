import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FiGift } from 'react-icons/fi';
import { FaBell } from 'react-icons/fa';
import styles from "./styles/Nav.module.css";
import Logo from "../../assets/icons/Logo";

function Nav(props) {
    const navigate = useNavigate();
    let [showNav, setShowNav] = useState(false);

    const redirectToMovies = () => {
        navigate("/movies");
    }

    const eventListenerFn = () => {
        if (window.scrollY > 100) {
            setShowNav(true)
        } else {
            setShowNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", eventListenerFn);
        return () => {
            window.removeEventListener("scroll", eventListenerFn);
        }
    }, [])


    const handleLogOut = () => {
        navigate("/")
        //   auth.signOut()
        //        .then(() => navigate("/"))
        //        .catch((err) => console.log(err));
    }
    return (
        <nav className={`${styles.Nav} ${showNav && `${styles.Nav_black}`}`}>
            <div className={styles.Nav__1}>
                {/* <img className={styles.Nav_logo} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcxyD4z8RcftP5qP5rAd9q0ff2BK6LADyYnw&usqp=CAU"
                    alt="WebStream Logo" onClick={redirectToMovies} /> */}

                <Logo />
                <div className={styles.links}>
                    <NavLink className={styles.link} to="/movies" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined}>Home</NavLink>
                    <NavLink className={styles.link} to="/category/tamil_hd" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined}>Tamil HD</NavLink>
                    <NavLink className={styles.link} to="/category/tamil_tc" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined}>Tamil TC</NavLink>
                    <NavLink className={styles.link} to="/category/tamil_dubbed" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined}>Tamil Dubbed</NavLink>

                    <NavLink className={styles.link} to="/hash-play" style={({ isActive }) => isActive ? { fontWeight: 'bold' } : undefined}>Custom URL</NavLink>


                </div>
            </div>

            <div className={styles.Nav__3}>
                <button className={styles.logout} onClick={handleLogOut}>Logout</button>
            </div>
        </nav>
    )
}

export default Nav;
