const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";
const CURRENT_USER = "CURRENT_USER";

const MANAGE_APP = {
  MANAGE_APP: "MANAGE_APP",
  VIDEO_POPUP: "MANAGE_APP_VIDEO_POPUP",
  MOVIE_DETAILS: "MANAGE_APP_MOVIE_DETAILS",
};

export {
  USER_LOGIN,
  USER_LOGOUT,
  CURRENT_USER,
  MANAGE_APP,
};
